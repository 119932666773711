import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '../../../../resources/email-icon.png';
import { Button, Tooltip } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import getPostBody, { savePost, removePost } from './PostUtil';
import LoginDialog from "../../../Dialog/LoginDialog";
import { Dimensions } from 'react-native';
import Enum from "../../../../enums/Enum";

const EmailLogo = styled.img`
  width: 3rem; 
  height: auto; 
  float: right; 
  margin-right: 1rem; 
  margin-bottom: .5rem;
  @media (max-width: 1000px) {
    width: 2rem;
  }
`;
const PostCardStyle = {
  marginBottom: '.25rem',
  marginTop: '2rem',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  width: 'calc(100% - 6rem)'
}
const PostCardStyleSmall = {
  marginBottom: '.25rem',
  marginTop: '2rem',
  width: '100%'
}
const PostBodyTypography = {
  fontSize: '1.25rem',
  fontFamily: 'Roboto'
}
const PostBodyTypographySmall = {
  fontSize: '1rem',
  fontFamily: 'Roboto'
}
const PostTitleTypography = {
  marginBottom: '1rem',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Roboto'
}
const theme = createTheme()
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
};
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600',
  '@media (max-width: 1000px)': {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4',
  '@media (max-width: 1000px)': {
    fontSize: '.65rem',
  },
};

export default function EmailPost({
  contentMatchesSmallestWidth,
  post,
}) {
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const [saved, setSaved] = useState(false)

  const PostCardStyle = {
    marginBottom: '.1rem',
    marginTop: '1.5rem',
    paddingLeft: contentMatchesSmallestWidth ? 'initial' : '3rem',
    paddingRight: contentMatchesSmallestWidth ? 'initial' : '3rem',
    width: '100%',
    position: 'relative',
    wordWrap: 'break-word'
  }
  const PostBodyTypography = {
    fontSize: contentMatchesSmallestWidth ? '1rem' : '1.25rem',
    fontFamily: 'Roboto'
  }


  return (
    <>
      <Card style={contentMatchesSmallestWidth ? PostCardStyleSmall : PostCardStyle}>
        <CardContent>
          <ThemeProvider theme={theme}>
            <div style={{ position: contentMatchesSmallestWidth ? 'relative' : 'absolute', top: 10, left: contentMatchesSmallestWidth ? -110 : 30 }}>
              {saved ?
                <Tooltip title="Remove from Saved" placement="top">
                  <Button onClick={() => removePost(post, setSaved)}>
                    <BookmarkAddedIcon style={{ color: Enum.WebsiteColors.Green }} />
                  </Button>
                </Tooltip>
                :
                <Tooltip title="Save Email" placement="top">
                  <Button onClick={() => savePost(post, setShowLoginDialog, setSaved)}>
                    <FileDownloadIcon style={{ color: Enum.WebsiteColors.Maroon }} />
                  </Button>
                </Tooltip>
              }
            </div>
            <Typography variant="h5" component="div" style={PostTitleTypography}>
              {post.data.title}
            </Typography>
            {
              <div dangerouslySetInnerHTML={{ __html: post.data.body }} />
            }
          </ThemeProvider>
        </CardContent>
        <div style={{ display: 'inline' }}>
          <EmailLogo
            src={EmailIcon}
            alt="Gmail Logo"
          />
        </div>
      </Card>
      {showLoginDialog && <LoginDialog setShowLoginDialog={setShowLoginDialog} />}
    </>
  )
}
