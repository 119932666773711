import React, { useState, useEffect } from "react";
import './App.css';
import AppView from './components/AppView/AppView';
import { BrowserRouter, Routes, Route, redirect, useSearchParams } from "react-router-dom";
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Settings from './components/Settings/Settings';
import Admin from "./components/Admin/Admin";
import ApiService from "./services/ApiService";
import { PageProvider } from "./PageContext";

import LandingPage from "@Pages/LandingPage";
import { ThemeProvider } from "./context/ThemeContext";

function App() {
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [currentSettingsPage, setCurrentSettingsPage] = useState('Personalization')

  const authToken = localStorage.getItem('token');

  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }

  useEffect(() => {
    const settingsPage = localStorage.getItem('SettingsPage')
    if (settingsPage) {
      setCurrentSettingsPage(settingsPage)
      localStorage.removeItem('SettingsPage')
    }

    getIsAdminUser()
      .then((isAdmin) => setIsAdminUser(isAdmin))

  }, [])

  const updateCurrentSettingsPage = (page) => {
    setCurrentSettingsPage(page)
  }

  const getIsAdminUser = async () => {
    if (authToken == null) return;  // only try to set admin for logged in user
    try {
      const res = await ApiService.getIsAdminUser(options)
      return await res.json()
    } catch (err) {
      return console.error(err)
    }
  }

  return (
    <PageProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <LandingPage />
              }
            />
            <Route
              path="login"
              element={
                <Login />
              }
            />
            <Route
              path="home"
              element={
                <AppView
                  isAdminUser={isAdminUser}
                />
              }
            />
            <Route
              path="settings"
              element={
                <Settings
                  appCurrentSettingsPage={currentSettingsPage}
                  appSetCurrentSettingsPage={setCurrentSettingsPage}
                  isAdminUser={isAdminUser}
                />
              }
            />
            <Route
              path="admin"
              element={
                isAdminUser ?
                  <Admin />
                  :
                  <Home
                    appUpdateCurrentSettingsPage={updateCurrentSettingsPage}
                  />
              }
            />
            <Route
              path="app"
              element={
                <AppView
                  isAdminUser={isAdminUser}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PageProvider>
  );
}

export default App;
