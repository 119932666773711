import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import Enum from '../../../../../enums/Enum';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      border: '1px solid black',
      boxShadow: '0 0 0 1px rgba(0,0,0,1)',
    },
  },
}));

export default function TransactionTypeSelect({ type, setType }) {
  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <div>
      <FormControl variant="standard" fullWidth>
        <NativeSelect
          id="demo-customized-select-native"
          value={type}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option aria-label="None" value="" />
          <option value={Enum.TransactionType.Income}>Income</option>
          <option value={Enum.TransactionType.Expense}>Expense</option>
        </NativeSelect>
      </FormControl>
    </div>
  );
}