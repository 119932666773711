import React from "react";
import Section from "@Typed/components/layout/Section";
import ButtonLink from "@Typed/components/buttons/ButtonLink";


export default function ToolsSection() {
    return (
        <div className="px-6 py-8 lg:px-8">
            <Section animate direction="right" id="tools">
                <div className="flex flex-col items-center w-full max-w-screen-xl gap-32 p-4 mx-auto md:flex-row ">
                    <img src="/images/andrea-piacquadio-3818963.jpg" className="order-last hidden rounded-lg shadow-xl w-80 shadow-black/25 md:order-first sm:block" />
                    <div className="text-left">
                        <h1 className="text-2xl font-bold tracking-tight text-blue-500 font-averia sm:text-4xl ">
                            Intuitive Tools
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-inherit">
                            We offer tools that make it easy to learn about any family-related topics you need, without the endless searching or confusing, contradictory advice. We also offer planning tools designed to simplify assisted reproductive technologies, including IVF, and more!
                        </p>
                        <div className="flex flex-col items-start justify-center w-full gap-4 mt-8">
                            <div className="flex items-center w-full gap-2 px-8 py-2 bg-white rounded-full">
                                <img src="/images/search-icon.png" className="w-8 h-8" />
                                <p className="ml-4 text-lg font-bold text-gray-600 grow font-averia">
                                    Family Topics Search Tool
                                </p>
                                <ButtonLink>Begin your search</ButtonLink>
                            </div>
                            <hr className="w-full border-gray-300" />
                            <div className="flex items-center w-full gap-2 px-8 py-2 bg-white rounded-full">
                                <img src="/images/pencil-icon.png" className="w-8 h-8" />
                                <p className="ml-4 text-lg font-bold text-gray-600 grow font-averia">
                                    Family Planner
                                </p>
                                <ButtonLink>Start planning</ButtonLink>
                            </div>
                            <hr className="w-full border-gray-300" />
                            <div className="flex items-center w-full gap-2 px-8 py-2 bg-white rounded-full">
                                <img src="/images/ivf-icon.png" className="w-8 h-8" />
                                <p className="ml-4 text-lg font-bold text-gray-600 grow font-averia">
                                    Fertility Planner
                                </p>
                                <ButtonLink>Design your fertility plan</ButtonLink>
                            </div>

                        </div>
                        <div className="flex items-center justify-end mt-10">
                            <a href="#" className="font-semibold leading-6 text-blue-500 hover:text-blue-800">
                                More tools <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div> 
            </Section>
        </div>
    )
}