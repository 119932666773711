import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoginSignUpButton } from "../styles/LoginSignupButton.styled";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ForgotPassword = styled.span`
	float: right;
	margin-top: .5rem;
	color: grey;
	font-family: Roboto;
`;
const LoginCardContainer = styled.div`
  text-align: center;
`;
const LoginCardInnerContainer = styled.div`
	display: inline-block; 
  margin: 0 auto;
	width: 90%;
  padding: '1rem'
`;

export default function LoginCardContent({ usernameLoginText, handleUsernameLoginFieldChanged, 
  passwordLoginText, handlePasswordLoginFieldChanged, submitLogin }) {

    const [matchesSmallHeight, setMatchesSmallHeight] = useState(
      window.matchMedia("(max-height: 725px)").matches
    )
    const TextFieldStyle = {
      marginLeft: '0px', 
      marginBottom: matchesSmallHeight ? '.5rem' : '1.25rem',
    }
    const LoginSignupLabelTypography = {
      textAlign: 'left',
      fontFamily: 'Roboto', 
      color: 'gray', 
      marginBottom: matchesSmallHeight ? '1rem' : '1.5rem',
      marginTop: matchesSmallHeight ? '0px' : '.5rem',
      fontWeight: 'bold',
    }
    
    useEffect(() => {
      window
        .matchMedia("(max-height: 725px)")
        .addEventListener('change', e => setMatchesSmallHeight( e.matches ))
    }, [])

  return (
    <CardContent>
      <LoginCardContainer>
        <Button 
          href="/home" 
          size="large"
          style={{ float: 'left' }}  
          startIcon={<ArrowBackIcon fontSize="large" style={{ color: 'grey' }} />} 
        />
        <LoginCardInnerContainer>
          <Typography variant="h5" component="div" style={LoginSignupLabelTypography}>
            Login
          </Typography>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { mb: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              error={false}
              id="username-login-text"
              label="Enter Username"
              defaultValue=""
              helperText=""
              style={TextFieldStyle}
              value={usernameLoginText}
              onChange={handleUsernameLoginFieldChanged}
            />
            <TextField
              fullWidth 
              error={false}
              id="password-login-text"
              label="Enter Password"
              defaultValue=""
              helperText=""
              style={TextFieldStyle}
              type="password"
              value={passwordLoginText}
              onChange={handlePasswordLoginFieldChanged}
            />
          </Box>
        </LoginCardInnerContainer>
      </LoginCardContainer>
      <LoginCardInnerContainer>
        <FormControlLabel
          style={{marginLeft: '4%'}} 
          control={<Checkbox defaultChecked style={{color: '#ff1d58'}} />} 
          label="Remember me?" 
        />
      </LoginCardInnerContainer>
      <LoginCardContainer>
        <LoginCardInnerContainer>
          <Button variant="contained" style={LoginSignUpButton} onClick={submitLogin}>Login</Button>
          <ForgotPassword>
            Forgot Password?
          </ForgotPassword>
        </LoginCardInnerContainer>
      </LoginCardContainer>
    </CardContent>
  )
}