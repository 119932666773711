import React from 'react'

const Header = ({text}) => {
  return (
    <h2>
      {text}
    </h2>
  )
}

export default Header
