import React from "react";

import Navbar from "@Typed/components/navigation/Navbar";
import HeroSection from "@Features/HeroSection";
import BenefitsSection from "@Features/BenefitsSection";
import FamilyDesignSection from "@Features/FamilyDesignSection";
import ToolsSection from "@Features/ToolsSection";
import ServicesSection from "@Features/ServicesSection";
import CommunitySection from "@Features/CommunitySection";

export default function UTMPage() {

    return (
        <div className="w-full h-full bg-fixed bg-center bg-no-repeat bg-cover bg-hero-pattern">
            <Navbar />
            <HeroSection />
            <ToolsSection />
            <ServicesSection />
        </div>
    );
}