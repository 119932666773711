import * as React from 'react';
import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart';
import { GlobalContext } from "../../../../../context/GlobalState";
import styled from "styled-components";
import Enum from '../../../../../enums/Enum';

const Container = styled.div`
  width: 500px;
  margin-top: 1rem;
`

export default function PieChart({ label, type }) {
  const [data, setData] = React.useState([])
  const { transactions } = React.useContext(GlobalContext);

  React.useEffect(() => {
    let newData = []
    transactions.map(function (transaction, i) {
      if (transaction.amount < 0 && type == Enum.TransactionType.Expense ||
          transaction.amount > 0 && type == Enum.TransactionType.Income) {
        newData = [...newData, { 
          id: i, 
          value: Math.abs(transaction.amount), 
          label: transaction.text 
        }]
      }
    });
    setData(newData)
  }, [transactions])

  return (
    <Container>
      <h4>{label}</h4>
      <MuiPieChart
        series={[
          {
            data,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
          },
        ]}
        height={200}
      />
    </Container>
  );
}