import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SpendingTracker from './BudgetTool/SpendingTracker/SpendingTracker';
import styled from "styled-components";
import HomeIcon from '@mui/icons-material/Home';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton } from '@mui/material';
import { usePage, usePageUpdate } from '../../../PageContext';
import ExpenseTrackerIcon from '../../../resources/expense_tracker.png';

const Planner = styled.div`
  background-color: #E8E8E8;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const DashboardHeader = styled.div`
  background-color: #fff;
  border-radius: .5rem;
  font-size: 1.6rem;
  font-weight: 500;
  height: fit-content;
  width: fit-content;
  color: #585858;
  padding: .3rem .5rem;
  display: flex;
  align-items: center;
`;

const DashboardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 3rem;
  grid-auto-rows: minmax(100px, auto);
  height: 90%;
  padding: 2rem;
`;

const ContentHeader = styled.div`
  border-radius: .5rem;
  font-size: 1.4rem;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  color: #585858;
  padding: .5rem;
`;

const SavedArticlesColumnStyle = styled.div`
  border-radius: .5rem;
  background: white;
  // border: 2px solid black;
  grid-column: 1;
  grid-row: 1 / 4;
`;

const SchedulingColumnStyle = styled.div`
  border-radius: .5rem;
  background: white;
  // border: 2px solid black;
  position: relative;
  grid-column: 2;
  grid-row: 1 / 2;
`;

const MicroserviceColumnStyle = styled.div`
  // border-radius: .5rem;
  // background: white;
  // border: 2px solid black;
  position: relative;
  grid-column: 3;
  grid-row: 1 / 2;
`; 

const MicroserviceStyle = styled.div(({ microservice }) => ({
  borderRadius: '1rem',
  background: 'white',
  backgroundImage: (microservice === 'Spending Tracker') ? `url(${ExpenseTrackerIcon})` : 'inherit',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '150px',
  marginBottom: '1rem'
}))

const InfoColumnStyle = styled.div`
  border-radius: .5rem;
  background: white;
  // border: 2px solid black;
  grid-column: 2 / 4;
  grid-row: 2 / 4;
`;

const HomeIconStyle = {
  background: 'rgb(36, 107, 124)', 
  borderRadius: '.3rem', 
  color: 'white', 
  marginRight: '.8rem',
  fontSize: '1.8rem',
}

const OpenInFullStyle = { 
  position: 'absolute', 
  top: 15, 
  right: 20, 
  background: 'rgb(36, 107, 124)', 
  color: 'white',
  borderRadius: '.5rem',
  fontSize: '1.7rem',
  padding: '.2rem',
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function FamilyPlanner() {
  const [value, setValue] = React.useState(0);
  const [microservices, setMicroservices] = React.useState(['Spending Tracker', 'Budget Tool', 'Survey', 'Future Tool'])
  const updatePage = usePageUpdate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Planner>
      <HeaderContainer>
        <DashboardHeader>
          <HomeIcon 
            sx={HomeIconStyle} 
          />
          My Dashboard
        </DashboardHeader>
      </HeaderContainer>
      <DashboardContent>
        <SavedArticlesColumnStyle>
          <ContentHeader>Saved Articles</ContentHeader>
        </SavedArticlesColumnStyle>
        <SchedulingColumnStyle>
          <ContentHeader>Journey Concierge</ContentHeader>
          <IconButton 
              sx={OpenInFullStyle}
              onClick={() => updatePage('chatBox')}
            >
              <OpenInFullIcon />
          </IconButton>
        </SchedulingColumnStyle>
        <MicroserviceColumnStyle>
          {microservices.map(microservice => {
            return ( 
              <MicroserviceStyle microservice={microservice}>
                <ContentHeader>
                  {microservice}
                </ContentHeader>
                <IconButton 
                  sx={OpenInFullStyle}
                  onClick={() => updatePage('spendingTracker')}
                >
                  <OpenInFullIcon />
                </IconButton>
              </MicroserviceStyle>
            )
          })}
        </MicroserviceColumnStyle>
        <InfoColumnStyle>
          <ContentHeader>Tracker</ContentHeader>
        </InfoColumnStyle>
      </DashboardContent>
    </Planner>
  );
}
