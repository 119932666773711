import React from "react";

import Navbar from "@Typed/components/navigation/Navbar";
import HeroSection from "@Features/HeroSection";
import BenefitsSection from "@Features/BenefitsSection";
import FamilyDesignSection from "@Features/FamilyDesignSection";
import ToolsSection from "@Features/ToolsSection";
import ServicesSection from "@Features/ServicesSection";
import CommunitySection from "@Features/CommunitySection";


// This is an example of a page that is shown when the URL contains 'utm_source=facebook'

export default function UTMFacebook() {

    return (
        <div className="w-full h-full bg-fixed bg-center bg-no-repeat bg-cover bg-hero-pattern">
            <h1 className="text-4xl text-center text-white">UTM Facebook</h1>
            <HeroSection />
            <ToolsSection />
            <ServicesSection />
        </div>
    );
}