import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Button, Divider, Grid, Typography } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsTab from './SettingsTab';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Enum from '../../enums/Enum';

const SidePanelContainer = styled.div`
  font-family: Roboto; 
  font-size: 1rem; 
  margin-top: 1rem;
  @media (min-width: 1451px) {
    width: 25%;
  }
  @media (max-width: 1450px) {
    width: 30%;
  }
  @media (max-width: 1350px) {
    width: 50%;
  }
  @media (max-width: 750px) {
    width: 100%;
    font-size: 1rem; 
    font-weight: 500;
    text-align: center;
    font-family: Roboto;
  }
`;
const SettingsTypography = styled.div`
  padding-left: .25rem; 
  font-size: 5rem; 
  font-weight: 900;
  font-family: Roboto;
`;
const BackArrowContainer = styled.div(({ color }) => ({
  "&hover": {
    background: color
  }
}))

export default function SettingsSidePanel({
  appSetCurrentSettingsPage,
  settingsSidebarOpen,
  handleToggleSidebar
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 750px)").matches
  )

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  useEffect(() => {
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => setMatchesSmallestWidth(e.matches))
  }, [])

  return (
    <>
      {matchesSmallestWidth && !settingsSidebarOpen ? <></> :
        <SidePanelContainer>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <BackArrowContainer color={Enum.WebsiteColors.Maroon}>
              <Grid item xs={2}>
                <Button variant="text" href="/home" sx={{ color: 'black' }}>
                  <ArrowBackIcon />
                </Button>
              </Grid>
            </BackArrowContainer>
            <SettingsTypography>
              <Typography variant="h5" component="div">
                Settings
              </Typography>
            </SettingsTypography>
          </Grid>
          <Divider />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"Personalization"}
            icon={<PsychologyAltIcon />}
          />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"Payments & Billing"}
            icon={<PaymentIcon />}
          />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"Account"}
            icon={<PermIdentityIcon />}
          />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"Topic Subs"}
            icon={<LoyaltyIcon />}
          />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"Help & Support"}
            icon={<SupportAgentIcon />}
          />
          <SettingsTab
            appSetCurrentSettingsPage={appSetCurrentSettingsPage}
            handleToggleSidebar={handleToggleSidebar}
            pageName={"About"}
            icon={<InfoIcon />}
          />
          <div style={{ marginTop: '100%' }} />
        </SidePanelContainer>
      }
    </>
  )
}
