import React, { HTMLAttributes, useRef } from "react";
import useIsVisible, { ObserverProps } from "@Hooks/useIsVisible";

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
    animate?: boolean;
    direction?: "left" | "right" | "up" | "down";
    isVisibileProps?: ObserverProps;
}

export default function Section(params: SectionProps) {

    const {children, className, ...props} = params;
    const ref = useRef<HTMLDivElement>(null);
    const isVisibleProps = {ref, ...params.isVisibileProps}
    const isVisible = useIsVisible(isVisibleProps);

    let directionClass = "";
    switch (params.direction) {
        case "left":
            directionClass = `${isVisible ? "translate-x-0" : "-translate-x-full"}`;
            break;
        case "right":
            directionClass = `${isVisible ? "translate-x-0" : "translate-x-full"}`;
            break;
        case "down":
            directionClass = `${isVisible ? "translate-y-0" : "-translate-y-full"}`;
            break;
        case "up":
            directionClass = `${isVisible ? "translate-y-0" : "translate-y-full"}`;
            break;
        default:
            directionClass = `${isVisible ? "translate-x-0" : "-translate-x-full"}`;
            break;
    }
    
    const animationClass = params.animate ? ` transition-all ease-in duration-700 ${directionClass}` : "";

    return (
        <div ref={ref} className={className + animationClass} {...props}>
            {children}
        </div>
    )

}