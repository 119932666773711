import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import Searchbar from "@Typed/components/inputs/Searchbar";
import NavbarItem from "./NavbarItem";

const navigation = [

    { name: 'Learn', href: '#' },
    { name: 'Plan', href: '#' },
    { name: 'Connect', href: '#' },
    { name: 'Log in', href: 'login'}
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  

export default function Navbar() {
    const [scroll, setScroll] = useState(0);
    const [textColor, setTextColor] = useState(255);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            setScroll(Math.min(scrollY / windowHeight, 1));
            setTextColor(255 - scrollY / windowHeight * 255);
        };
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <Disclosure
            as="nav"
            className="fixed top-0 left-0 right-0 z-50"
            style={{ 
                backgroundColor: `rgba(255, 255, 255, ${scroll})`,
                color: `rgba(${textColor},${textColor},${textColor},1)`,
            }}
        >
            { ({open}) => (
                <>
                <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="relative flex flex-col items-start justify-between gap-4 py-4 sm:flex-row sm:items-center">
                        <div className="flex items-center w-full sm:hidden">
                            <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-inherit hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                                ) : (
                                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                            <div className="flex items-center flex-shrink-0 mx-auto">
                                <img
                                    className="w-auto h-12"
                                    src="/images/m21f-logo-white.png"
                                    alt="Your Company"
                                    style={{filter: `invert(${scroll*100}%)`}}
                                />
                            </div>
                        </div>
                        <Disclosure.Panel className="w-full sm:hidden">
                            <div className="flex flex-col w-full gap-2 px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="w-full px-3 py-3 font-medium rounded-md text-inherit hover:bg-blue-500"
                                >
                                {item.name}
                                </Disclosure.Button>
                            ))}
                            </div>
                        </Disclosure.Panel>

                        <div className="flex justify-center flex-1 w-full gap-2 items-bottom sm:inset-auto sm:items-center sm:justify-center">
                            <div className="items-center flex-shrink-0 hidden flex-basis-1/4 grow sm:flex">
                                <img
                                    className="w-auto h-16"
                                    src="/images/m21f-logo-white.png"
                                    alt="Your Company"
                                    style={{filter: `invert(${scroll*100}%)`}}
                                />
                                <span className="font-bold text-inherit font-averia grow">Modern<br/>21 Family</span>
                            </div>

                            <div className="w-full flex-basis-1/2 justify-self-center">
                                <Searchbar />
                            </div>
                            <div className="hidden gap-4 flex-basis-1/4 grow sm:flex sm:justify-self-center whitespace-nowrap">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="px-3 py-2 font-bold rounded-md text-inherit hover:bg-blue-500"
                                    >
                                        {item.name}
                                    </a>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </Disclosure>
    )
}