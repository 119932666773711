import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { getAccountInfo, updateAccount, getQuestions } from '../SettingsUtil';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MoreInfo from './MoreInfo';
import Enum from '../../../enums/Enum';
import { Button, Grid, TextField, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackArrowContainer = styled.div(({ color }) => ({
  "&hover .Button": {
    background: color
  }
}))

const LabelTypographyWithMargin = {
  fontWeight: '600',
  marginTop: '1.5rem',
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Personalization({
  settingsSidebarOpen,
  settingsmainMatchesSmallestWidth,
  settingsUserInfo,
  settingsSetUserInfo,
  handleToggleSidebar,
  color,
}) {
  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [gridWidth, setGridWidth] = useState('65%')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [about, setAbout] = useState('')
  const [subbedTopics, setSubbedTopics] = useState('')
  const [firstTrait, setFirstTrait] = useState('')
  const [secondTrait, setSecondTrait] = useState('')
  const [thirdTrait, setThirdTrait] = useState('')
  const [fourthTrait, setFourthTrait] = useState('')
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [value, setValue] = React.useState(0)
  const [matchesMedWidth, setMatchesMedWidth] = useState(
    window.matchMedia("(max-width: 2150px)").matches
  )
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 950px)").matches
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  useEffect(() => {
    getAccountInfo(setEmail, setUsername, setAbout, setSubbedTopics,
      setFirstTrait, setSecondTrait, setThirdTrait, setFourthTrait)
    getQuestions(setFirstTrait, setSecondTrait, setThirdTrait, setFourthTrait)

    window
      .matchMedia("(max-width: 2150px)")
      .addEventListener('change', e => {
        setMatchesMedWidth(e.matches)
        setGridWidth('100%')
      })
    window
      .matchMedia("(max-width: 750px)")
      .addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
        setGridWidth('100%')
      })
  }, [])

  const save = () => {
    setShowSuccessMessage(false)
    setShowErrorMessage(false)
    let fields = {
      username: username,
      email: email,
      about: about,
      subscribedTopics: subbedTopics,
      traitOne: firstTrait,
      traitTwo: secondTrait,
      traitThree: thirdTrait,
      traitFour: fourthTrait,
    }

    updateAccount(fields)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'Success') {
          setShowSuccessMessage(true)
        } else {
          setShowErrorMessage(true)
        }
        setSnackbarOpen(true)
        return getAccountInfo(setEmail, setUsername, setAbout, setSubbedTopics,
          setFirstTrait, setSecondTrait, setThirdTrait, setFourthTrait)
      })
      .catch((err) => {
        console.error(err)
        setShowErrorMessage(true)
        setSnackbarOpen(true)
      })
  }

  return (
    <div style={{ width: matchesSmallestWidth ? '100%' : 'calc(83.33% - 1rem)' }}>
      <Box sx={{ width: '100%' }}>
        {settingsmainMatchesSmallestWidth && !settingsSidebarOpen &&
          <BackArrowContainer color={Enum.WebsiteColors.LightGreen} onClick={handleToggleSidebar}>
            <Grid item xs={1}>
              <Button
                variant="text"
                sx={{ color: 'black', marginRight: '.5rem' }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </BackArrowContainer>
        }
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Settings tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab style={{ background: 'white', color: color }} label="About Me" {...a11yProps(0)} />
            <Tab style={{ background: 'white', color: color }} label="My Answers" {...a11yProps(1)} />
            <Tab style={{ background: 'white', color: color }} label="Legal Settings" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Typography variant="h7" component="div" sx={LabelTypographyWithMargin}>
            About You
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            value={settingsUserInfo.about}
            onChange={(event) => {
              settingsSetUserInfo(current => {
                return { ...current, about: event.target.value }
              })
            }}
            sx={{ width: settingsmainMatchesSmallestWidth ? '100%' : 'calc(83.33% - 1rem)', marginTop: '.25rem' }}
          />

          <Divider sx={{ marginTop: '1.5rem' }} />

          <Typography variant="h7" component="div" sx={LabelTypographyWithMargin}>
            Lifestyle
          </Typography>

          <p>Location Information</p>

          {/*           // Add a location selector with google autocomplete 
          // https://www.npmjs.com/package/react-google-autocomplete
          
          // 3 Drop Downs
          // Select one: Romantic (orientation_romantic)
          // Select one: Family (orientation_family)
          // Multi-Select: Financial (orientation_financial) */}
          <p>Situation Information</p>
        </TabPanel>

        <TabPanel value={value} index={1}>
          
        </TabPanel>

        <TabPanel value={value} index={2}>

        </TabPanel>
      </Box>
    </div>
  )
}
