
const Sources = {
  Reddit: 'Reddit',
  Youtube: 'Youtube',
  Gmail: 'Gmail',
  Internal: 'Internal',
}

const Topics = {
  Recommended: 'Recommended',
  NaturalFertility: 'Natural Fertility',
  FamilyDynamics: 'Family Dynamics',
  ReproductiveTechnology: 'Reproductive Technology',
  Legal: 'Legal',
  PlanningLogistics: 'Planning & Logistics',
  Community: 'Community',
  Saved: 'Saved Posts',
  Search: 'Search Results',
}

const WebsiteColors = {
  Green: 'rgb(36, 107, 124)',
  Maroon: 'rgb(184, 51, 106)',
  Violet: 'rgb(196, 144, 209)',
  LightGreen: 'rgba(36, 107, 124, 0.1)',
}

const TransactionType = {
  Expense: 'expense',
  Income: 'income'
}

export default {
  Sources,
  WebsiteColors,
  Topics,
  TransactionType,
}
