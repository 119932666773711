import React from "react";
import TopicMainPanel from "../TopicPage/TopicMainPanel";

export default function HomePage({
  appviewPosts,
  appviewSetPosts,
  appviewTags,
  appviewSetTags,
  appviewReloadFeed,
  appviewSetReloadFeed,
}) {

  return (
    <TopicMainPanel
      appviewPosts={appviewPosts}
      appviewSetPosts={appviewSetPosts}
      appviewTags={appviewTags}
      appviewSetTags={appviewSetTags}
      appviewReloadFeed={appviewReloadFeed}
      appviewSetReloadFeed={appviewSetReloadFeed}
      topicId={0}
      topicName={"Recommended"}
    />
  )
}
