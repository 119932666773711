import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HomeToolbar from "./Toolbar";
import ImagePanel from "./ImagePanel";
import TextPanel from "./TextPanel";
import ImagePanelOverlay from "./ImagePanelOverlay";
import { Typography } from "@mui/material";
import postImage from '../../resources/post.png'
import filterImage from '../../resources/filter.png'
import hoverImage from '../../resources/hover.png'
import './HomeStyles.css';

function useOnScreen(options) {
	const ref = React.useRef()
	const [visible, setVisible] = React.useState(false)

	React.useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setVisible(entry.isIntersecting)
		}, options)

		if (ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current)
			}
		}
	}, [ref, options])

	return [ref, visible]
}

export default function Home({ appUpdateCurrentSettingsPage }) {
	const [ref, visible] = useOnScreen({ rootMargin: '0px' })
	const [ref2, visible2] = useOnScreen({ rootMargin: '0px' })
	const [ref3, visible3] = useOnScreen({ rootMargin: '0px' })
	const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
		window.matchMedia("(max-width: 800px)").matches
	)
	const [matchesSmallWidth, setMatchesSmallWidth] = useState(
		window.matchMedia("(max-width: 1125px)").matches
	)
	const [matchesMedWidth, setMatchesMedWidth] = useState(
		window.matchMedia("(max-width: 1500px)").matches
	)
	const HeadingStyle = {
		fontSize: matchesSmallestWidth ? '2rem' : '3rem',
		lineHeight: '120%',
		marginTop: '3rem',
		marginBottom: '1.5rem',
		fontWeight: 800
	}
	const ParagraphStyle = {
		fontSize: 'clamp(16px, 2vw, 20px)',
		lineHeight: 1.625
	}
	const BlockOneStyle = {
		maxWidth: '1500px',
		height: matchesSmallWidth ? '300px' : '700px',
		opacity: visible ? 1 : 0,
		filter: visible ? 'blur(0)' : 'blur(5px)',
		transform: visible ? 'translateX(0)' : 'translateX(-100%)',
		transition: 'all 1s',
		width: 'fit-content',
		padding: '2rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		borderRadius: '1rem',
		marginTop: matchesSmallWidth ? '0' : '2rem',
		marginBottom: matchesSmallWidth ? '0rem' : '6rem'
	}
	const BlockTwoStyle = {
		background: '#f6f6f6',
		maxWidth: '1500px',
		height: matchesSmallWidth ? '300px' : '500px',
		opacity: visible2 ? 1 : 0,
		filter: visible2 ? 'blur(0)' : 'blur(5px)',
		transform: visible2 ? 'translateX(0)' : 'translateX(100%)',
		transition: 'all 1s',
		width: 'fit-content',
		padding: matchesSmallWidth ? '.5rem' : '2rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		borderRadius: '1rem',
		marginTop: '2rem',
		marginBottom: matchesSmallWidth ? '0' : '6rem'
	}
	const BlockThreeStyle = {
		maxWidth: '1500px',
		height: matchesSmallWidth ? '300px' : '400px',
		opacity: visible3 ? 1 : 0,
		filter: visible3 ? 'blur(0)' : 'blur(5px)',
		transform: visible3 ? 'translateX(0)' : 'translateX(-100%)',
		transition: 'all 1s',
		width: 'fit-content',
		padding: '2rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		borderRadius: '1rem',
		marginTop: '2rem',
		marginBottom: '6rem'
	}

	useEffect(() => {
		window
			.matchMedia("(max-width: 800px)")
			.addEventListener('change', e => {
				setMatchesSmallestWidth(e.matches)
			})
		window
			.matchMedia("(max-width: 1125px)")
			.addEventListener('change', e => {
				setMatchesSmallWidth(e.matches)
			})
		window
			.matchMedia("(max-width: 1500px)")
			.addEventListener('change', e => {
				setMatchesMedWidth(e.matches)
			})
	}, [])

	return (
		<div style={{ overflow: 'hidden' }}>
			<HomeToolbar />
			<ImagePanel />
			<ImagePanelOverlay
				homeMatchesSmallestWidth={matchesSmallestWidth}
			/>
			<TextPanel
				homeMatchesMedWidth={matchesMedWidth}
				homeMatchesSmallestWidth={matchesSmallestWidth}
			/>
			<div style={{ paddingTop: matchesSmallWidth ? '1rem' : '5rem' }}>
				<div ref={ref} style={BlockOneStyle}>
					{!matchesSmallWidth &&
						<img
							src={postImage}
							alt="post image"
							width={'auto'}
							height={matchesMedWidth ? '75%' : '100%'}
						/>
					}
					<div style={{ float: matchesSmallWidth ? 'none' : 'right', marginRight: matchesSmallWidth ? 'auto' : '5rem', marginLeft: matchesSmallWidth ? 'auto' : 'none', maxWidth: matchesSmallWidth ? '85%' : '25%' }}>
						<Typography style={HeadingStyle}>
							Find easy to read, relevant and quality information
						</Typography>
						<Typography style={ParagraphStyle}>
							We make it easy to find the best content about fertility and family planning. 
							LGBTQ+ diverse solutions, options and more on popular topics such as Intrauterine Insemination,
							In Vitro Fertilization, Egg Freezing, Surrogacy, Male Infertility Treatment, ICSI Fertility Treatment and more. 
							We offer many tools to help you filter and digest information on all of these topics. 

							
						</Typography> 
					</div>
				</div>
				<div ref={ref2} style={BlockTwoStyle}>
					{!matchesSmallWidth &&
						<img
							src={filterImage}
							alt="filter image"
							width={'auto'}
							height={matchesMedWidth ? '60%' : '70%'}
							style={{ marginTop: '2rem' }}
						/>
					}
					<div style={{ float: matchesSmallWidth ? 'none' : 'left', height: matchesSmallWidth ? '200px' : '100%', marginLeft: matchesSmallWidth ? 'auto' : '2rem', marginRight: matchesSmallWidth ? 'auto' : 'none', maxWidth: matchesSmallWidth ? '85%' : '25%' }}>
						<Typography style={HeadingStyle}>
							Filter out noise to find what works for you
						</Typography>
						<Typography style={ParagraphStyle}>
							Automatic filters based on your personal preferences. 
							It's our job to make finding what you need easy and painless! You can customize by dynamic 
							such as lesbian, gay, single parent, enm, blended family, multifamily, and more.  
						</Typography>
					</div>
				</div>
				<div ref={ref3} style={BlockThreeStyle}>
					{!matchesSmallWidth &&
						<img
							src={hoverImage}
							alt="hover image"
							width={'auto'}
							height={'100%'}
							style={{ marginTop: '4rem' }}
						/>
					}
					<div style={{ marginBottom: '3rem', float: matchesSmallWidth ? 'none' : 'right', height: '100%', maxWidth: matchesSmallWidth ? '85%' : '25%', marginRight: matchesSmallWidth ? 'auto' : '10rem', marginLeft: matchesSmallWidth ? 'auto' : '0', marginTop: '1rem' }}>
						<Typography style={HeadingStyle}>
							Tools to help eliminate confusion, stress and speed you along your journey, so you spend more time in the fun zone
						</Typography>
						<Typography style={ParagraphStyle}>
							Tooltips, calendar integrations, concierge services, mapping out steps to your journey 
						</Typography>
					</div>
				</div>
				<div class="footer">
					<div class="footer-column">
						<p style={{ color: '#5865F2' }}>Resources</p>
						<p><a href="/Settings" class="home" onClick={() => { localStorage.setItem('SettingsPage', 'Help & Support') }}>Support</a></p>
						<p><a href="#" class="home">More from AIO</a></p>
					</div>
					<div class="footer-column">
						<p style={{ color: '#5865F2' }}>Company</p>
						<p><a href="/Settings" class="home" onClick={() => { localStorage.setItem('SettingsPage', 'About') }}>About Us</a></p>
						<p><a href="#" class="home">Partnerships</a></p>
						<p><a href="mailto:help@allisones.com" class="home">Contact Us</a></p>
					</div>
					<div class="footer-column">
						<p style={{ color: '#5865F2' }}>Legal</p>
						<p><a href="#" class="home">Topics</a></p> 
						<p><a href="#" class="home">Privacy</a></p>
					</div>
				</div>
			</div>
		</div>
	)
}
