import React, { useContext, useState } from 'react';

const ThemeContext = React.createContext()
const ThemeUpdateContext = React.createContext()

export function useTheme() {
  return useContext(ThemeContext)
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext)
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('Default')

  function updateTheme(newTheme) {
    setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeUpdateContext.Provider value={setTheme}>
        { children }
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  )
}