import React, {useEffect, useState, RefObject} from "react";

export interface ObserverProps extends IntersectionObserverInit {
    once?: boolean;
}

export interface useIsVisibleProps extends ObserverProps {
    ref: RefObject<Element>;
}

export default function useIsVisible({
    ref,
    root = null,
    rootMargin = "0px",
    threshold = 0.0,
    once = true,
}: useIsVisibleProps) {
    const [isVisible, setIsVisible] = useState(false);

    const frozen = isVisible && once;

    useEffect(() => {
        const element = ref.current;

        // Check if IntersectionObserver is available
        const hasObserverSupport = !!window.IntersectionObserver;

        // If not supported, fallback to simply showing the children
        if (!hasObserverSupport || !element || frozen) return;

        const observerParams = { root, rootMargin, threshold };
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            }, observerParams);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, root, rootMargin, threshold, once]);

    return isVisible;
}