import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import YouTube from 'react-youtube';
import YoutubeIcon from '../../../../resources/youtube-icon.png';
import { savePost, removePost } from './PostUtil';
import { Accordion, AccordionDetails, AccordionSummary, Button, Tooltip } from "@mui/material";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoginDialog from "../../../Dialog/LoginDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getVideoDescription } from './PostUtil';
import Enum from "../../../../enums/Enum";

const YoutubeLogo = styled.img`
  width: 3rem; 
  height: auto; 
  float: right; 
  margin-right: 1rem; 
  margin-bottom: .5rem;
  @media (max-width: 1000px) {
    width: 2rem;
  }
`;
const PostTitleTypography = {
  marginBottom: '1rem',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Roboto'
}
const theme = createTheme();
theme.typography.h5 = {
  fontSize: '1.5rem',
  '@media (max-width: 1000px)': {
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
};
theme.typography.h6 = {
  fontSize: '1.25rem',
  fontWeight: '600'
};
theme.typography.body2 = {
  fontSize: '1.5rem',
  lineHeight: '1.4'
};

export default function YoutubePost({
  contentMatchesSmallestWidth,
  contentMatchesMedWidth,
  post,
}) {
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const [saved, setSaved] = useState(false)

  const PostCardStyle = {
    marginBottom: '.1rem',
    marginTop: '1.5rem',
    width: '100%',
    paddingLeft: contentMatchesSmallestWidth ? 'initial' : '3rem',
    paddingRight: contentMatchesSmallestWidth ? 'initial' : '3rem',
    position: 'relative',
    wordWrap: 'break-word'
  }

  const PostBodyTypography = {
    fontSize: contentMatchesSmallestWidth ? '1rem' : '1.15rem',
    lineHeight: '1.5',
    marginTop: '1rem',
    textAlign: 'center',
    fontFamily: 'Roboto'
  }

  const onPlayerReady = (event) => {
    event.target.pauseVideo()
  }

  const opts = {
    height: contentMatchesSmallestWidth ? '300px' : '500px',
    width: contentMatchesMedWidth ? '90%' : '50%',
    playerVars: {
      autoplay: 0,
    },
  }

  return (
    <>
      <Card style={PostCardStyle}>
        <CardContent sx={{ paddingBottom: 0, paddingTop: '2rem' }}>
          <ThemeProvider theme={theme}>
            <div style={{
              position: contentMatchesSmallestWidth ? 'relative' : 'absolute',
              top: contentMatchesSmallestWidth ? 5 : 10,
              left: contentMatchesSmallestWidth ? -130 : 30
            }}>
              {saved ?
                <Tooltip title="Remove from Saved" placement="top">
                  <Button onClick={() => removePost(post, setSaved)}>
                    <BookmarkAddedIcon style={{ color: Enum.WebsiteColors.Green }} />
                  </Button>
                </Tooltip>
                :
                <Tooltip title="Save Video" placement="top">
                  <Button onClick={() => savePost(post, setShowLoginDialog, setSaved)}>
                    <FileDownloadIcon style={{ color: Enum.WebsiteColors.Maroon }} />
                  </Button>
                </Tooltip>
              }
            </div>
            <Typography
              variant="h5"
              component="div"
              style={PostTitleTypography}
            >
              {post.data.title}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              style={PostBodyTypography}
            >
              <div dangerouslySetInnerHTML={{ __html: getVideoDescription(post.data.description) }} />
            </Typography>
          </ThemeProvider>
        </CardContent>
        <YoutubeLogo
          src={YoutubeIcon}
          alt="Youtube Logo"
        />
      </Card>
      <div style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Expand to Watch Video</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <YouTube
              videoId={post.data.videoId}
              opts={opts}
              onReady={onPlayerReady}
              style={{ marginLeft: 'auto', marginRight: 'auto', width: 'auto' }}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      {showLoginDialog && <LoginDialog setShowLoginDialog={setShowLoginDialog} />}
    </>
  )
}
