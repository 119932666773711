import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import './SpendingTracker.css';
import Balance from "./Balance";
import { IncomeExpenses } from "./IncomeExpenses";
import { TransactionList } from "./TransactionList";
import { AddTransaction } from "./AddTransaction";
import { GlobalProvider } from "../../../../../context/GlobalState";
import PieChart from "./PieChart";
import Enum from "../../../../../enums/Enum";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from "@mui/material";
import { usePage, usePageUpdate } from '../../../../../PageContext';

const Rows = styled.div`
  display: flex; 
  flex-direction: row; 
  margin-top: 1rem; 
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height: 100vh;
  margin: 0;
  margin-left: 3rem; 
  font-family: 'Lato', sans-serif;
`

export default function SpendingTracker() {
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(
    window.matchMedia("(max-width: 320px)").matches
  )
  const Container = {
    margin: '30px auto',
    width: matchesSmallestWidth ? '300px' : '400px',
    padding: '1rem',
    // boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  }
  const updatePage = usePageUpdate()

	useEffect(() => {
		window
			.matchMedia("(max-width: 320px)")
			.addEventListener('change', e => {
        setMatchesSmallestWidth(e.matches)
      })
  }, [])

  return (
    <GlobalProvider>
      <div style={{ background: '#f7f7f7', paddingTop: '1rem' }}>
        <Button 
          startIcon={
            <ArrowBackIcon />
          }
          sx={{ 
            color: 'rgba(0, 0, 0, .7)',
            marginBottom: '5rem',
            marginLeft: '1rem'
          }}
          onClick={() => updatePage('familyPlanner')}
        >
          Back to Dashboard
        </Button>
        <Rows>
          <Body>
            <Header text={"Expense Tracker"} />
            <div style={Container}>
              <Balance />
              <IncomeExpenses />
              <TransactionList />
              <AddTransaction />
            </div>
          </Body>
          <div style={{ margin: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PieChart 
              label={"Expenses"}
              type={Enum.TransactionType.Expense} 
            />
            <PieChart 
              label={"Budget"}
              type={Enum.TransactionType.Income} 
            />
          </div>
        </Rows>
      </div>
    </GlobalProvider>
  )
}