import React from "react";
import HomePage from "./HomePage/HomePage";
import TopicMainPanel from "./TopicPage/TopicMainPanel";
import { usePage } from "../../PageContext";
import Quiz from "./FamilyPlanner/Quiz/Quiz";
import Enum from "../../enums/Enum"
import SpendingTracker from "./FamilyPlanner/BudgetTool/SpendingTracker/SpendingTracker";
import FamilyPlanner from "./FamilyPlanner/FamilyPlanner";
import JourneyConcierge from "./FamilyPlanner/JourneyConcierge/JourneyConcierge";

export default function MainPanel({
  appviewTags,
  appviewSetTags,
  appviewPosts,
  appviewSetPosts,
  appviewTopics,
  appviewReloadFeed,
  appviewSetReloadFeed,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const activePage = usePage()  // the current page

  const props = {
    appviewTags,
    appviewSetTags,
    appviewPosts,
    appviewSetPosts,
    appviewTopics,
    appviewReloadFeed,
    appviewSetReloadFeed,
  }

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------


  return (
    <>
      {activePage === 'home' &&
        <HomePage
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
        />
      }
      {activePage === 'familyPlanner' &&
        <FamilyPlanner {...props} />
      }
      {activePage === 'spendingTracker' &&
        <SpendingTracker />  
      }
      {activePage === 'chatBox' &&
        <JourneyConcierge />
      }
      {activePage === Enum.Topics.Recommended &&
        <TopicMainPanel
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={0}
          topicName={Enum.Topics.Recommended}
        />
      }
      {appviewTopics[activePage] &&
        <TopicMainPanel
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={appviewTopics[activePage]}
          topicName={activePage}
        />
      }
      {activePage === Enum.Topics.Saved &&
        <TopicMainPanel
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={-1}
          topicName={Enum.Topics.Saved}
        />
      }
      {activePage === Enum.Topics.Search &&
        <TopicMainPanel
          appviewTags={appviewTags}
          appviewSetTags={appviewSetTags}
          appviewPosts={appviewPosts}
          appviewSetPosts={appviewSetPosts}
          appviewReloadFeed={appviewReloadFeed}
          appviewSetReloadFeed={appviewSetReloadFeed}
          topicId={-2}
          topicName={Enum.Topics.Search}
        />
      }
    </>
  )
}
