import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import FamilyPlannerSidePanel from "../FamilyPlannerSidePanel";
import Header from "../Header";
import TopicButtonGroup from "../TopicButtonGroup";
import TextDivider from "../TopicDivider";
import styled from "styled-components";
import ApiService from "../../../services/ApiService";
import { useTheme } from '../../../context/ThemeContext';

const DividerWrapper = styled.div`
  margin: 1rem;
`;
const Footer = styled.div`
  height: calc(3% - 2.5rem);
  margin-top: calc(3% + .5rem);
  text-align: center;
  color: grey;
  font-family: Roboto;
  font-size: small;
`;
const HomePageSidePanelContainer = styled.div`
  height: 100%; 
  box-shadow: rgba(0, 0, 0, 0.12) 1px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
  overflow-y: auto;
`;
const Spacer = styled.div`
  margin-top: 2rem;
`;
const Contents = styled.div`
  height: 95%;
`;

export default function HomePageSidePanel({
  handleMenuClick,
  isPremiumUser
}) {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState()
  const appTheme = useTheme()

  useEffect(() => {
    if (localStorage.getItem("token") == null) return;
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    ApiService.isUserAuthenticated(options)
      .then((res) => res.json())
      .then((data) => {
        setIsUserAuthenticated(data.status === 'Success')
      })
      .catch((err) => console.error(err))
  }, [])

  return (
    <HomePageSidePanelContainer>
      <Contents>
        <Header isUserAuthenticated={isUserAuthenticated} />
        <Spacer />
        <TextDivider text={"Family Planner"} />
        <FamilyPlannerSidePanel
          isPremiumUser={isPremiumUser}
          isUserAuthenticated={isUserAuthenticated} 
        />
        <Spacer />
        <TextDivider text={"Topics"} theme={appTheme} />
        <TopicButtonGroup
          handleMenuClick={handleMenuClick}
        />
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
      </Contents>
      <Footer>An AIO Product</Footer>
    </HomePageSidePanelContainer>
  )
}
