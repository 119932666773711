import React from "react";
import HomePageSidePanel from "./HomePageSidePanel/HomePageSidePanel";

export default function SidePanel({
  handleMenuClick,
  isPremiumUser
}) {
  return (
    <HomePageSidePanel
      handleMenuClick={handleMenuClick}
      isPremiumUser={isPremiumUser}
    />
  )
}
