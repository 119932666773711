import { useState, useEffect } from 'react';
import styled from "styled-components";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '../Toolbar/Toolbar';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import SettingsSidePanel from './SettingsSidePanel';
import SettingsMainPanel from './SettingsMainPanel';
import { getAccountInfo, updateAccount } from './SettingsUtil';
import Enum from '../../enums/Enum';
import ApiService from '../../services/ApiService';

const SettingsContainer = styled.div(({ color }) => ({
  width: '100vw',
  height: 'calc(100vh - 64px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: color,
}))
const StyledCard = styled(Card)`
  @media (min-width: 1901px) {
    width: 70%;
  }
  @media (max-width: 1900px) {
    width: 80%;
  }
  @media (max-width: 1650px) {
    width: 90%;
  }
  height: 90%;
`;
const StyledDivider = styled(Divider)`
  @media (max-width: 750px) {
    display: none;
  }
`;
const StyledBox = {
  display: 'flex',
  width: '100%',
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: 1,
  bgcolor: 'background.paper',
  color: 'text.secondary',
  '& svg': {
    m: 1.5,
  },
  '& hr': {
    mx: 0.5,
  },
}

export default function Settings({
  appCurrentSettingsPage,
  appSetCurrentSettingsPage,
  isAdminUser,
}) {

  // -------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isPremiumUser, setIsPremiumUser] = useState(false)
  const [userInfo, setUserInfo] = useState({
    email: '',
    username: '',
    about: '',
    subbedTopics: '',
  })


  useEffect(() => {
    getIsPremiumUser()
  }, [])

  const getIsPremiumUser = () => {
    ApiService.getIsPremiumUser({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setIsPremiumUser(data)
      })
      .catch((err) => console.error(err))
  }

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  useEffect(() => {
    getAccountInfo(setUserInfo)
  }, [])

  const saveAccountInfo = () => {
    setShowSuccessMessage(false)
    setShowErrorMessage(false)
    updateAccount(userInfo)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'Success') {
          setShowSuccessMessage(true)
        } else {
          setShowErrorMessage(true)
        }
        setSnackbarOpen(true)
        return getAccountInfo(setUserInfo)
      })
      .catch((err) => {
        console.error(err)
        setShowErrorMessage(true)
        setSnackbarOpen(true)
      })
  }

  return (
    <>
      <Toolbar 
        callingFrom={"Settings"} 
        isPremiumUser={isPremiumUser}
        setIsPremiumUser={setIsPremiumUser}
        isAdminUser={isAdminUser}
      />
      <SettingsContainer color={Enum.WebsiteColors.LightGreen}>
        <StyledCard style={{ overflowY: 'auto' }}>
          <CardContent>
            <Box sx={StyledBox}>
              <SettingsSidePanel
                appSetCurrentSettingsPage={appSetCurrentSettingsPage}
                settingsSidebarOpen={sidebarOpen}
                handleToggleSidebar={handleToggleSidebar}
              />
              <StyledDivider orientation="vertical" variant="middle" flexItem />
              <SettingsMainPanel
                appCurrentSettingsPage={appCurrentSettingsPage}
                settingsUserInfo={userInfo}
                settingsSetUserInfo={setUserInfo}
                settingsSidebarOpen={sidebarOpen}
                settingsShowSuccessMessage={showSuccessMessage}
                settingsShowErrorMessage={showErrorMessage}
                settingsSnackbarOpen={snackbarOpen}
                settingsSetSnackbarOpen={setSnackbarOpen}
                saveAccountInfo={saveAccountInfo}
                handleToggleSidebar={handleToggleSidebar}
                isPremiumUser={isPremiumUser}
                setIsPremiumUser={setIsPremiumUser}
              />
            </Box>
          </CardContent>
        </StyledCard>
      </SettingsContainer>
    </>
  )
}
