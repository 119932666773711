import React from "react";

export default function Tooltip(props: React.HTMLAttributes<HTMLDivElement>) {

    const {children, className, ...rest} = props;

    return (
        <div className="absolute top-0 z-10 hidden p-4 text-sm font-normal text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-lg shadow-lg left-1/2 w-96 group-hover:block">
            {children}
        </div>
    )
}