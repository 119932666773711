import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function Searchbar() {
    return (
        <div className="relative w-full mx-auto text-neutral-800">
            <label htmlFor="default-search" className="mb-2 text-sm font-medium sr-only">Search</label>
            {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5 text-neutral-500" aria-hidden="true" />
            </div> */}
            <input
                type="text"
                name="search"
                id="search"
                className="block w-full max-w-sm py-2 pl-4 pr-3 mx-auto leading-5 placeholder-blue-500 transition-all bg-white border border-gray-300 rounded-full focus:max-w-xl focus:h-12 focus:outline-none focus:ring-primary-200 focus:border-primary-200 focus:z-10 sm:text-sm"
                placeholder="Have questions? Find answers..."
            />
        </div>

    )
}