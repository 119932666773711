import React, { useState, useEffect } from "react";
import { useTheme, useThemeUpdate } from '../../context/ThemeContext'; 
import { themeContext, themeUpdateContext } from '../../context/ThemeContext';
import { TopicButton } from "../styles/TopicButton.styled";
import { TopicButtonGroupContainer } from "../styles/TopicButtonGroupContainer.styled";
import { usePage, usePageUpdate } from "../../PageContext";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ApiService from "../../services/ApiService";
import Enum from "../../enums/Enum";
const recommended = {
  gridArea: "recommended",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const fertility = {
  gridArea: "fertility",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const planning = {
  gridArea: "planning",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const reproTech = {
  gridArea: "repro-tech",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const legal = {
  gridArea: "legal",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const community = {
  gridArea: "community",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const family = {
  gridArea: "family",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const savedPosts = {
  gridArea: "saved",
  display: "grid",
  fontSize: '1.5rem',
  alignItems: "center",
  justifyItems: "center",
  padding: '0 1rem',
}
const recommendedTitle = 'Recommended shows you a curated list of Youtube videos, emails, and Reddit posts related to fertiliy and family planning.'
const naturalFertilityTitle = 'Information about natural fertility and infertility.'
const planningTitle = 'Information about family planning and care logistics.'
const reprodTechTitle = 'Information about reproductive technology and assisted fertility.'
const legalTitle = 'Information about legal options, procedures and resources.'
const familyDynamicsTitle = 'Information about relationships, social and cultural dynamics. Minority inclusive and supportive resources.'
const communityTitle = 'Information about communities.'
const savedTitle = 'User saved posts.'

export default function TopicButtonGroup({
  handleMenuClick,
}) {
  const appTheme = useTheme()
  const setAppTheme = useThemeUpdate()
  const activePage = usePage()
  const updatePage = usePageUpdate()
  const [subbedTopics, setSubbedTopics] = useState('')
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(window.matchMedia("(max-width: 1000px)").matches)
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  /// This hook updates the subscribed topics when a user logs in
  useEffect(() => {
    window.matchMedia("(max-width: 1000px)")
      .addEventListener('change', e => setMatchesSmallestWidth(e.matches))
    if (localStorage.getItem("token") == null) return;
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    ApiService.getAccountInfo(options)
      .then((res) => res.json())
      .then((data) => {
        setIsAuthenticated(true);
        setSubbedTopics(data.subbed_topics);
      })
      .catch((err) => console.error(err))
  }, [])

  /// Update the theme when the page changes
  const handleTopicButtonClick = (newPage, newTheme) => {
    handleMenuClick()
    updatePage(newPage)
    setAppTheme(newTheme)
  }

  let color = appTheme === 'Default' ? Enum.WebsiteColors.Green : appTheme === 'Maroon' ? Enum.WebsiteColors.Maroon : Enum.WebsiteColors.Violet

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: color,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: color,
      color: 'white',
      width: 220,
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '1.2rem',
      lineHeight: '1.5',
      height: 'fit-content',
      padding: '1rem',
    },
  }))
  let placement = matchesSmallestWidth ? 'top' : 'right'
  return (
    <TopicButtonGroupContainer>
      <BootstrapTooltip title={recommendedTitle} placement={placement}>
        <TopicButton
          style={recommended}
          color={color}
          onClick={() => {
            handleTopicButtonClick(Enum.Topics.Recommended, 'Default')
          }}
        >
          {Enum.Topics.Recommended}
        </TopicButton>
      </BootstrapTooltip>
      {

      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.NaturalFertility) || subbedTopics === '') &&
        <BootstrapTooltip title={naturalFertilityTitle} placement={placement}>
          <TopicButton
            style={fertility}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.NaturalFertility, 'Violet')
            }}
          >
            {Enum.Topics.NaturalFertility}
          </TopicButton>
        </BootstrapTooltip>
      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.FamilyDynamics) || subbedTopics === '') &&
        <BootstrapTooltip title={familyDynamicsTitle} placement={placement}>
          <TopicButton
            style={family}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.FamilyDynamics, 'Violet')
            }}
          >
            {Enum.Topics.FamilyDynamics}
          </TopicButton>
        </BootstrapTooltip>
      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.ReproductiveTechnology) || subbedTopics === '') &&
        <BootstrapTooltip title={reprodTechTitle} placement={placement}>
          <TopicButton
            style={reproTech}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.ReproductiveTechnology, 'Default')
            }}
          >
            {Enum.Topics.ReproductiveTechnology}
          </TopicButton>
        </BootstrapTooltip>
      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.Legal) || subbedTopics === '') &&
        <BootstrapTooltip title={legalTitle} placement={placement}>
          <TopicButton
            style={legal}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.Legal, 'Maroon')
            }}
          >
            {Enum.Topics.Legal}
          </TopicButton>
        </BootstrapTooltip>
      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.PlanningLogistics) || subbedTopics === '') &&
        <BootstrapTooltip title={planningTitle} placement={placement}>
          <TopicButton
            style={planning}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.PlanningLogistics, 'Maroon')
            }}
          >
            {Enum.Topics.PlanningLogistics}
          </TopicButton>
        </BootstrapTooltip>
      }
      {(!subbedTopics || subbedTopics.includes(Enum.Topics.Community) || subbedTopics === '') &&
        <BootstrapTooltip title={communityTitle} placement={placement}>
          <TopicButton
            style={community}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.Community, 'Violet')
            }}
          >
            {Enum.Topics.Community}
          </TopicButton>
        </BootstrapTooltip>
      }
      {isAuthenticated &&
        <BootstrapTooltip title={savedTitle} placement={placement}>
          <TopicButton
            style={savedPosts}
            color={color}
            onClick={() => {
              handleTopicButtonClick(Enum.Topics.Saved, 'Default')
            }}
          >
            Saved Posts
          </TopicButton>
        </BootstrapTooltip>}
    </TopicButtonGroupContainer>
  )
}
