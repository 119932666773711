import React, {useState, useContext} from 'react'
import { GlobalContext } from '../../../../../context/GlobalState';
import TransactionTypeSelect from './TransactionTypeSelect';
import Enum from '../../../../../enums/Enum';

export const AddTransaction = () => {
  const [text, setText] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = React.useState('');

  const { addTransaction } = useContext(GlobalContext);

  const onSubmit = e => {
    e.preventDefault();

    var newAmount = (type == Enum.TransactionType.Expense) ? +amount * -1 : +amount

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: newAmount,
    }

    addTransaction(newTransaction);
    setText('')
    setType('')
    setAmount('')
  }

  return (
    <>
      <h3>Add new transaction</h3>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <label htmlFor="text">What was it for?</label>
          <input 
            type="text" 
            value={text} 
            onChange={(e) => setText(e.target.value)} placeholder="Enter transaction..." 
          />
        </div>
        <div className="form-control">
          <label htmlFor="type" style={{ margin: '10px 0' }}>
            Transaction Type
          </label>
          <TransactionTypeSelect 
            type={type}
            setType={setType}
          />
          <label htmlFor="amount">
            Amount
          </label>
          <input 
            type="number" 
            value={amount} 
            onChange={(e) => setAmount(e.target.value)} placeholder="Enter amount..." 
          />
        </div>
        <button className="btn">
          Add transaction
        </button>
      </form>
    </>
  )
}