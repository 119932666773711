import React from "react";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


export default function JourneyConcierge() {
  
  return (
    <TawkMessengerReact
      propertyId="65ef712a9131ed19d97864f7"
      widgetId="1honl43j5"
    />
  )
}