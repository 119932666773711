import React, {useState, useEffect} from "react";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import Section from "@Typed/components/layout/Section";
import Waves from "@Typed/components/dividers/Waves";
import ButtonLink from "@Typed/components/buttons/ButtonLink";

export default function HeroSection() {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            setOpacity(Math.min(scrollY / windowHeight, 1));
        };
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    function handleLink (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        localStorage.setItem('SettingsPage', 'About');
        window.location.href = '/settings';
    }

    return (
        <div className="h-screen">
            <Section className="h-full" animate={false} direction="left">
                <div className="relative h-full px-6 py-32 lg:px-8">
                    <div className="max-w-2xl mx-auto" style={{ opacity: 1 - opacity*4 }}>
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white font-averia sm:text-6xl ">
                                Modern solutions for your modern family
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600 font-bold"> 
                            Tools and services to help you create a happy, healthy family
                            </p>
                            <div className="flex items-center justify-center mt-10 gap-x-6">
                                <ButtonLink href="/home" buttonColor="blue">Get started</ButtonLink>
                                <a href="/settings" onClick={(e) => handleLink(e)} className="font-semibold leading-6 text-gray-900">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-x-0 bottom-0 w-full font-bold text-center bg-blue-500" style={{ opacity: 1 - opacity*4 }}>
                        <p className="my-auto leading-8">
                            Scroll down to learn more
                        </p>
                        <ArrowDownIcon className="w-5 h-5 mx-auto my-auto animate-bounce" />
                    </div>
                </div>
            </Section>
            <Waves className="bottom-0 rotate-180 -scale-x-100" style={{ opacity: opacity*3}}/>
        </div>
        
    )
}