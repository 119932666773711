import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Card from '@mui/material/Card';
import ApiService from "../../services/ApiService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Navigate } from "react-router-dom";
import LoginCardContent from "./LoginCardContent";
import SignupCardContent from "./SignupCardContent";
import Divider from '@mui/material/Divider';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Button } from "@mui/material";
import Enum from "../../enums/Enum";

const OrFromDivider = styled.div`
	border: 1px solid grey; 
	border-radius: 5px; 
	padding: 5px; 
	color: grey; 
	font-family: Roboto;
`;
const LoginCard = styled(Card)`
	width: 20vw; 
	height: 70vh;

	@media (min-height: 1151px) {
		height: 85vh;
	}
	@media (max-height: 1150px) {
		height: 85vh;
	}
	@media (max-width: 2200px) {
		width: 25vw;
	}
	@media (max-width: 1600px) {
		width: 30vw;
	}
	@media (max-width: 1050px) {
		width: 40vw;
	}
	@media (max-width: 850px) {
		width: 50vw;
	}
	@media (max-width: 700px) {
		width: 60vw;
	}
	@media (max-width: 500px) {
		width: 70vw;
	}
	@media (max-width: 400px) {
		width: 80vw;
	}
`;
const LoginContainer = styled.div`
	background-image: linear-gradient(to bottom right, #6ed3cf, #0049B7); 
	width: 100vw; 
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Login() {
	const [usernameLoginText, setUsernameLoginText] = useState('')
	const [passwordLoginText, setPasswordLoginText] = useState('')
	const [usernameSignupText, setUsernameSignupText] = useState('')
	const [emailSignupText, setEmailSignupText] = useState('')
	const [passwordSignupText, setPasswordSignupText] = useState('')
	const [retypePasswordText, setRetypePasswordText] = useState('')
	const [loginSnackbarOpen, setLoginSnackbarOpen] = useState(false)
	const [signupSnackbarOpen, setSignupSnackbarOpen] = useState(false)
	const [loginSuccessful, setLoginSuccessful] = useState(false)
	const [signupSuccessful, setSignupSuccessful] = useState(false)
	const [readyToNavigate, setReadyToNavigate] = useState(false)
	const [autoHideDuration, setAutoHideDuration] = useState(2000)
	const [loginCardActive, setLoginCardActive] = useState(true)
	const [matchesLargeHeight, setMatchesLargeHeight] = useState(
    window.matchMedia("(min-height: 1115px)").matches
  )
	const [matchesLargeWidth, setMatchesLargeWidth] = useState(
    window.matchMedia("(min-width: 1150px)").matches
  )

  useEffect(() => {
    window
			.matchMedia("(min-height: 1115px)")
			.addEventListener('change', e => setMatchesLargeHeight(e.matches))
  }, [])

	useEffect(() => {
    window
			.matchMedia("(min-width: 1150px)")
			.addEventListener('change', e => setMatchesLargeWidth(e.matches))
  }, [])

	const submitLogin = () => {
		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				'username': usernameLoginText,
				'password': passwordLoginText,
			})
		}
		ApiService.login(options)
			.then(res => res.json())
			.then(data => {
				if ('token' in data) {
					localStorage.setItem('token', data.token)
					localStorage.setItem('username', data.username)
					localStorage.setItem('email', data.email)
					setLoginSuccessful(true)
					setAutoHideDuration(500)
				} 
				setLoginSnackbarOpen(true)
			})
			.catch(err => console.error(err))
	}
	
	const submitRegister = () => {
		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				'email': emailSignupText,
				'username': usernameSignupText,
				'password': passwordSignupText,
				'password2': retypePasswordText
			})
		}
		ApiService.register(options)
			.then(res => res.json())
			.then(data => {
				if ('token' in data) {
					localStorage.setItem('token', data.token)
					localStorage.setItem('username', data.username)
					localStorage.setItem('email', data.email)
					setSignupSuccessful(true)
					setAutoHideDuration(500)
				}
				setSignupSnackbarOpen(true)
			})
			.catch(err => console.error(err))
	}

	const handleLoginClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
		setLoginSnackbarOpen(false)
		setReadyToNavigate(true)
  }

	const handleSignupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
		setSignupSnackbarOpen(false)
		setReadyToNavigate(true)
  }

  return (
		<>
			<LoginContainer>
				{matchesLargeHeight ? 
					<LoginCard>
						<LoginCardContent
							usernameLoginText={usernameLoginText} 
							handleUsernameLoginFieldChanged={(e) => setUsernameLoginText(e.target.value)} 
							passwordLoginText={passwordLoginText} 
							handlePasswordLoginFieldChanged={(e) => setPasswordLoginText(e.target.value)} 
							submitLogin={submitLogin}
						/>
						<Divider style={{marginTop: '1.5rem'}}>
							<OrFromDivider>OR</OrFromDivider>
						</Divider>
						<SignupCardContent
							usernameSignupText={usernameSignupText}
							handleUsernameSignupFieldChanged={(e) => setUsernameSignupText(e.target.value)}
							emailSignupText={emailSignupText} 
							handleEmailSignupFieldChanged={(e) => setEmailSignupText(e.target.value)} 
							passwordSignupText={passwordSignupText} 
							handlePassswordSignupFieldChanged={(e) => setPasswordSignupText(e.target.value)} 
							retypePasswordText={retypePasswordText}
							handleRetypePassswordFieldChanged={(e) => setRetypePasswordText(e.target.value)} 
							submitRegister={submitRegister}
						/>
					</LoginCard>
				:
					<>
						{matchesLargeWidth ? 
							<>
								<LoginCard>
									<LoginCardContent
										usernameLoginText={usernameLoginText} 
										handleUsernameLoginFieldChanged={(e) => setUsernameLoginText(e.target.value)} 
										passwordLoginText={passwordLoginText} 
										handlePasswordLoginFieldChanged={(e) => setPasswordLoginText(e.target.value)} 
										submitLogin={submitLogin}
									/>
								</LoginCard>
								<LoginCard style={{marginLeft: '2rem'}}>
									<SignupCardContent
										usernameSignupText={usernameSignupText}
										handleUsernameSignupFieldChanged={(e) => setUsernameSignupText(e.target.value)}
										emailSignupText={emailSignupText} 
										handleEmailSignupFieldChanged={(e) => setEmailSignupText(e.target.value)} 
										passwordSignupText={passwordSignupText} 
										handlePassswordSignupFieldChanged={(e) => setPasswordSignupText(e.target.value)} 
										retypePasswordText={retypePasswordText}
										handleRetypePassswordFieldChanged={(e) => setRetypePasswordText(e.target.value)} 
										submitRegister={submitRegister}
									/>
								</LoginCard>
							</>
						: 
							<>
								{loginCardActive ? 
									<LoginCard>
										<LoginCardContent
											usernameLoginText={usernameLoginText} 
											handleUsernameLoginFieldChanged={(e) => setUsernameLoginText(e.target.value)} 
											passwordLoginText={passwordLoginText} 
											handlePasswordLoginFieldChanged={(e) => setPasswordLoginText(e.target.value)} 
											submitLogin={submitLogin}
										/>
										<Button 
											style={{margin: '0 auto', display: 'block', color: Enum.WebsiteColors.Green, marginTop: '1rem'}}
											onClick={() => setLoginCardActive(false)}
										>
												Or sign up<br/>
												<KeyboardDoubleArrowRightIcon fontSize="large" color={Enum.WebsiteColors.Green} />
										</Button>
									</LoginCard>
								:
									<LoginCard>
										<SignupCardContent
											usernameSignupText={usernameSignupText}
											handleUsernameSignupFieldChanged={(e) => setUsernameSignupText(e.target.value)}
											emailSignupText={emailSignupText} 
											handleEmailSignupFieldChanged={(e) => setEmailSignupText(e.target.value)} 
											passwordSignupText={passwordSignupText} 
											handlePassswordSignupFieldChanged={(e) => setPasswordSignupText(e.target.value)} 
											retypePasswordText={retypePasswordText}
											handleRetypePassswordFieldChanged={(e) => setRetypePasswordText(e.target.value)} 
											submitRegister={submitRegister}
										/>
										<Button 
											style={{margin: '0 auto', display: 'block', color: Enum.WebsiteColors.Green, marginTop: '1rem'}}
											onClick={() => setLoginCardActive(true)}
										>
												Or login<br/>
												<KeyboardDoubleArrowLeftIcon fontSize="large" color={Enum.WebsiteColors.Green} />
										</Button>
									</LoginCard>
								}
							</>
						}
					</>
				}
      </LoginContainer>
			<Stack 
				spacing={2} 
				sx={{ width: '100%' }}
			>
				<Snackbar 
					open={loginSnackbarOpen} 
					autoHideDuration={autoHideDuration} 
					onClose={handleLoginClose} 
					sx={{marginLeft: 'calc(50% - 150px)'}}
				>
					{loginSuccessful ? 
						<Alert onClose={handleLoginClose} severity="success" sx={{ width: '100%' }}>
							Login successful, welcome {usernameLoginText}!
						</Alert>
					:
						<Alert onClose={handleLoginClose} severity="error" sx={{ width: '100%' }}>
							Invalid username or password!
						</Alert>
					}
				</Snackbar>
				<Snackbar 
					open={signupSnackbarOpen} 
					autoHideDuration={autoHideDuration} 
					onClose={handleSignupClose} 
					sx={{marginLeft: 'calc(50% - 150px)'}}
				>
					{signupSuccessful ? 
						<Alert onClose={handleSignupClose} severity="success" sx={{ width: '100%' }}>
							Login successful, welcome {usernameSignupText}!
						</Alert>
					:
						<Alert onClose={handleSignupClose} severity="error" sx={{ width: '100%' }}>
							Username or password not available!
						</Alert>
					}
				</Snackbar>
    	</Stack>
			{(loginSuccessful || signupSuccessful) && readyToNavigate && 
				<Navigate to='/home' />
			}
    </>
  )
}
