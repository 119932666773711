import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import Enum from "../../enums/Enum";


export default function PremiumUserDialog({ setShowDialog }) {
  return (
    <Dialog
      open={true}
      onClose={() => setShowDialog(false)}
      aria-labelledby="question-dialog-title"
    > 
      <DialogTitle>
        It looks like you don't have a subscription.
      </DialogTitle>
      

      <DialogContent sx={{ paddingTop: 0 }}>
        Would you like to purchase a subscription so that you can have access
        to more cool features?
      </DialogContent>

      <DialogActions style={{ marginRight: '1rem', marginBottom: '.5rem' }}>
        <Button 
          onClick={() => setShowDialog(false)} 
          style={{ color: Enum.WebsiteColors.Green }}
        >
          No, thanks
        </Button>
        <Button
          href="/settings"
          startIcon={<LoginIcon />} 
          onClick={() => setShowDialog(false)} 
          style={{ color: Enum.WebsiteColors.Maroon, marginLeft: '1rem' }}
        >
          Go to Billing
        </Button>
      </DialogActions>

    </Dialog>
  )
}