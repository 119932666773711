import React, { useEffect, useState, useRef } from "react";
import ApiService from "../../../services/ApiService";
import Feed from "./Feed";
import QuestionDialog from '../../Dialog/QuestionDialog';
import { Button } from "@mui/material";
import { GridContainer, Main } from './TopicPageUtil';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Enum from "../../../enums/Enum";
import { usePage } from "../../../PageContext";
import { transformPost } from "../../../utils/PostTransform";

export default function TopicPageContentSection({
  appviewTags,
  appviewSetTags,
  appviewPosts,
  appviewReloadFeed,
  appviewSetReloadFeed,
  topicmainLoaded,
  topicmainSetLoaded,
  topicmainShowBackgroundImage,
  topicmainToggleShowBackgroundImage,
  scrollToTop,
  color,
  topicId,
  topicName,
}) {

  const activePage = usePage();  // the current page
  const pageRef = useRef("home");  // tracking the page for re-rendering page changes
  const contentRef = useRef({
    reddit: true,
    email: true,
    youtube: true,
    internal: true,
  });  // tracking the content filters
  const pageNumRef = useRef(1);  // tracking the page number for pagination
  const topicIdRef = useRef(topicId);  // tracking the topic id without re-renders


  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  const [posts, setPosts] = useState(new Map());
  const [savedPosts, setSavedPosts] = useState([])
  const [questionAnsweredInSession, setQuestionAnsweredInSession] = useState(false)
  const [sortBy, setSortBy] = useState([])
  const [showAll, setShowAll] = useState(true)
  const [showVideos, setShowVideos] = useState(false)
  const [showReddit, setShowReddit] = useState(false)
  const [showEmails, setShowEmails] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [showSavedPosts, setShowSavedPosts] = useState(false)
  const [matchesLargeWidth, setMatchesLargeWidth] = useState(window.matchMedia("(min-width: 2000px)").matches)
  const [matchesMedWidth, setMatchesMedWidth] = useState(window.matchMedia("(max-width: 1500px)").matches)
  const [matchesSmallWidth, setMatchesSmallWidth] = useState(window.matchMedia("(max-width: 1000px)").matches)
  const [matchesSmallestWidth, setMatchesSmallestWidth] = useState(window.matchMedia("(max-width: 750px)").matches)
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [numSignIns, setNumSignIns] = useState(0)
  const [accountInfoReady, setAccountInfoReady] = useState(false)
  const [whenStartFamilyResponse, setWhenStartFamilyResponse] = useState(undefined)
  const [hasNextPage, setHasNextPage] = useState(true)

  const ShowFiltersContainer = {
    marginTop: '8.5rem',
    color: color,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    borderRadius: '5px',
    padding: '.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
    height: 'fit-content'
  }

  /// This hook updates login information whenever a sign-in has occurred.
  /// This will potentially trigger other things
  useEffect(() => {
    if (localStorage.getItem("token") == null) return;
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    // Login
    ApiService.getAccountInfo(options)
      .then((res) => res.json())
      .then((data) => {
        setEmail(data.email)
        setUsername(data.username)
        setNumSignIns(data.num_sign_ins)
        setWhenStartFamilyResponse(data.when_start_family_response)
        setAccountInfoReady(true)
      })
      .catch((err) => console.error(err));
  }, []);


  /// This hook fetches post data from the API whenever the following state changes are met:
  /// - page change
  /// - topic change
  /// - content filter change
  /// - load more button
  useEffect(() => {
    // TODO: generalize. Currently this `getAllPosts` still only really gets reddit posts
    if (!appviewReloadFeed) return;
    appviewSetReloadFeed(false);

    if (pageNumRef.current === 1) topicmainSetLoaded(false);

    if (pageRef.current === Enum.Topics.Search) {
      setPosts(new Map(appviewPosts));
      topicmainSetLoaded(true);
      return;
    }

    if (pageRef.current === Enum.Topics.Saved) {
      if (localStorage.getItem("token") == null) return;
      let options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
      }
      ApiService.getSavedPosts(options)
        .then(response => response.json())
        .then(data => {
          setHasNextPage(data.hasNextPage);
          data.posts.forEach(((currentPost) => {
            const post = transformPost(currentPost);
            setPosts((prevPosts) => new Map(prevPosts.set(post.id, post)));
          }));
          topicmainSetLoaded(true);
        })
      return;
    }

    ApiService.getAllPosts(
      pageNumRef.current,
      topicIdRef.current,
      contentRef.current.reddit,
      contentRef.current.email,
      contentRef.current.youtube,
    )
      .then(response => response.json())
      .then(data => {
        setHasNextPage(data.hasNextPage);
        data.posts.forEach(((currentPost) => {
          const post = transformPost(currentPost);
          setPosts((prevPosts) => {
            return new Map(prevPosts.set(post.id, post))
          });
        }));
        topicmainSetLoaded(true);
      })
  }, [appviewReloadFeed]);


  /// This hook updates the page whenever the activePage changes, used to
  /// trigger other hooks
  useEffect(() => {
    pageRef.current = activePage;
    window.matchMedia("(max-width: 750px)")
      .addEventListener('change', e => setMatchesSmallestWidth(e.matches));
    window.matchMedia("(max-width: 1000px)")
      .addEventListener('change', e => setMatchesSmallWidth(e.matches));
    window.matchMedia("(max-width: 1500px)")
      .addEventListener('change', e => setMatchesMedWidth(e.matches));
    window.matchMedia("(min-width: 2000px)")
      .addEventListener('change', e => setMatchesLargeWidth(e.matches));
  }, [activePage]);

  // load more button, set page += 1
  const loadMore = () => {
    pageNumRef.current += 1;
    appviewSetReloadFeed(true);
  };

  const handleDropdownChange = (event) => {
    const {
      target: { value },
    } = event
    setSortBy(
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  // Reset the feed, by clearing all posts and setting the page to 1
  const resetFeed = () => {
    setPosts(new Map());
    pageNumRef.current = 1;
  }

  // Reset the feed if the topic id has changed
  useEffect(() => {
    resetFeed();
    topicIdRef.current = topicId;
    appviewSetReloadFeed(true);  // reload the feed
  }, [topicId, appviewSetReloadFeed])

  // Update the content filters
  useEffect(() => {
    let sAll = ((!showVideos && !showReddit && !showEmails) || (showVideos && showReddit && showEmails))
    if (sAll) {
      contentRef.current = { reddit: true, email: true, youtube: true, internal: true }
    }
    setShowAll(sAll)
  }, [showVideos, showReddit, showEmails])

  const updateContent = () => {
    contentRef.current.reddit = showReddit;
    contentRef.current.email = showEmails;
    contentRef.current.youtube = showVideos;
  }

  // Content change logic
  const handleCheckboxChange = (event, type) => {
    if (type === 'Videos') {
      setShowVideos(event.target.checked)
      if (showVideos) {
        if (showAll) {
          // only reset if we go from showing everything to showing one content type
          resetFeed();
        } else {
          updateContent();
        }
      }
    }
    if (type === 'Reddit') {
      setShowReddit(event.target.checked)
      if (showReddit) {
        if (showAll) {
          // only reset if we go from showing everything to showing one content type
          resetFeed();
        }
        updateContent();
      }
    }
    if (type === 'Emails') {
      setShowEmails(event.target.checked)
      if (showEmails) {
        if (showAll) {
          // only reset if we go from showing everything to showing one content type
          resetFeed();
        }
        updateContent();
      }
    }
  }

  const getSavedPosts = () => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    ApiService.getPosts(options)
      .then((res) => res.json())
      .then((data) => {
        if (data.length === 0) {
          setSavedPosts([])
        }
        data.forEach(savedPost => {
          const post = transformPost(savedPost);
          setSavedPosts([...post])
        })
      }).catch((err) => console.error(err))
  }

  if (showSavedPosts && savedPosts.length > 0) {
    setPosts([...savedPosts]);
  }

  // TODO: trigger for database update
  const dbUpdate = {

  }

  return (
    <>
      {numSignIns === 1 && !whenStartFamilyResponse &&
        <QuestionDialog
          question="To help us find things most helpful to you, we'd
            like to know how soon (if at all) you're looking to
            start a family."
          color={color}
        />
      }
      {matchesMedWidth ?
        <>
          {topicmainLoaded &&
            <Feed
              appviewTags={appviewTags}
              appviewSetTags={appviewSetTags}
              topicmainLoaded={topicmainLoaded}
              topicmainShowBackgroundImage={topicmainShowBackgroundImage}
              topicmainToggleShowBackgroundImage={topicmainToggleShowBackgroundImage}
              contentPage={pageNumRef.current}
              contentHasNextPage={hasNextPage}
              contentNumSignIns={numSignIns}
              contentAccountInfoReady={accountInfoReady}
              contentQuestionAnsweredInSession={questionAnsweredInSession}
              contentSetQuestionAnsweredInSession={setQuestionAnsweredInSession}
              contentPosts={posts}
              contentShowAll={showAll}
              contentShowVideos={showVideos}
              contentShowReddit={showReddit}
              contentShowEmails={showEmails}
              contentSavedPosts={savedPosts}
              contentShowSavedPosts={showSavedPosts}
              contentMatchesMedWidth={matchesMedWidth}
              contentMatchesSmallWidth={matchesSmallWidth}
              contentMatchesSmallestWidth={matchesSmallestWidth}
              handleDropdownChange={handleDropdownChange}
              handleCheckboxChange={handleCheckboxChange}
              getSavedPosts={getSavedPosts}
              loadMore={loadMore}
              reloadInternalPosts={dbUpdate}
              topicName={topicName}
              fromDialog={false}
            />
          }
        </>
        :
        <>
          <GridContainer showFilters={showFilters} fromContentPage={true}>
            {!showFilters && topicmainLoaded &&
              <Button style={ShowFiltersContainer} onClick={() => setShowFilters(!showFilters)}>
                <KeyboardDoubleArrowRightIcon />
              </Button>
            }
            {topicmainLoaded &&
              <Main>
                <Feed
                  appviewTags={appviewTags}
                  appviewSetTags={appviewSetTags}
                  topicmainLoaded={topicmainLoaded}
                  topicmainShowBackgroundImage={topicmainShowBackgroundImage}
                  topicmainToggleShowBackgroundImage={topicmainToggleShowBackgroundImage}
                  contentPage={pageNumRef.current}
                  contentHasNextPage={hasNextPage}
                  contentNumSignIns={numSignIns}
                  contentAccountInfoReady={accountInfoReady}
                  contentQuestionAnsweredInSession={questionAnsweredInSession}
                  contentSetQuestionAnsweredInSession={setQuestionAnsweredInSession}
                  contentPosts={posts}
                  contentShowAll={showAll}
                  contentShowVideos={showVideos}
                  contentShowReddit={showReddit}
                  contentShowEmails={showEmails}
                  contentSavedPosts={savedPosts}
                  contentShowSavedPosts={showSavedPosts}
                  contentMatchesMedWidth={matchesMedWidth}
                  contentMatchesSmallWidth={matchesSmallWidth}
                  contentMatchesSmallestWidth={matchesSmallestWidth}
                  handleDropdownChange={handleDropdownChange}
                  handleCheckboxChange={handleCheckboxChange}
                  getSavedPosts={getSavedPosts}
                  loadMore={loadMore}
                  reloadInternalPosts={dbUpdate}
                  topicName={topicName}
                  fromDialog={false}
                />
              </Main>
            }
          </GridContainer>
        </>
      }
    </>
  )
}
