import React from "react";
import Section from "@Typed/components/layout/Section";
import Waves from "@Typed/components/dividers/Waves";
import ButtonLink from "@Typed/components/buttons/ButtonLink";

export default function FamilyDesignSection() {
    return (
        <div className="h-[600px] relative px-0 sm:px-6 lg:px-8 bg-[url('/public/images/andrea-piacquadio-755049.jpg')] bg-fixed bg-center bg-no-repeat bg-cover">
            <Waves className="top-0"/>
            <div className="grid w-full h-full sm:grid-cols-1 justify-items-start md:grid-cols-2 md:justify-items-end ">
                <div className="z-10 flex items-center justify-center h-full bg-white sm:max-w-md lg:mr-32">
                    <Section animate direction="up" id="family-design" isVisibileProps={{rootMargin: "400px"}}>
                        <div className="px-8 my-auto text-left">
                            <h1 className="text-2xl font-bold tracking-tight text-jade-500 font-averia sm:text-4xl ">
                                Family Design
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Everyone should have the chance to shape their own family life. Families come in many different forms and deciding on your family's path can take a lot of time and work. What's right for you, and how do you achieve it? We're here to help everyone answer these questions in a straightforward and caring way.
                            </p>
                            <div className="flex flex-wrap items-center justify-start mt-8 gap-x-6">
                                <ButtonLink buttonColor="jade">Get answers</ButtonLink>
                                <a href="#" className="font-semibold leading-6 text-gray-900 hover:text-jade-600">
                                    Browse community resources <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            <Waves className="bottom-0 rotate-180"/>

        </div>
    )
}