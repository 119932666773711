import React from "react";

import { useSearchParams } from "react-router-dom";

import Navbar from "@Typed/components/navigation/Navbar";
import HeroSection from "@Features/HeroSection";
import BenefitsSection from "@Features/BenefitsSection";
import FamilyDesignSection from "@Features/FamilyDesignSection";
import ToolsSection from "@Features/ToolsSection";
import ServicesSection from "@Features/ServicesSection";
import CommunitySection from "@Features/CommunitySection";

import UTMPage from "./UTMPage";
import UTMFacebook from "./UTMFacebook";
import UTMTwitter from "./UTMTwitter";

export default function LandingPage() {

    // Get the search parameters from the URL
    const [searchParams] = useSearchParams()

    // Get all the keys from the search parameters
    const keys = [...searchParams.keys()];
    
    // If there are any 'utm' parameters in the URL, show the UTM page instead of the landing page
    const utm = keys.some(key => key.includes('utm'));

    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmContent = searchParams.get('utm_content');
    const utmTerm = searchParams.get('utm_term');

    if (utm) {
        if (utmSource === 'facebook') {
            return <UTMFacebook />;
        } else if (utmSource === 'twitter') {
            return <UTMTwitter />;
        } else {
            return <UTMPage />;
        }
    }

    return (
        <div className="w-full h-full bg-fixed bg-center bg-no-repeat bg-cover bg-hero-pattern">
            <Navbar />
            <HeroSection />
            <BenefitsSection />
            <FamilyDesignSection />
            <ToolsSection />
            <ServicesSection />
            <CommunitySection />
        </div>
    );
}