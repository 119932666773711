import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ApiService from '../../services/ApiService';
import { Typography } from '@mui/material';
import { Snackbar, Stack, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function PayPal(props) {
  const [purchaseSuccessful, setPurchaseSuccessful] = useState(false)
  const initialOptions = {
    "client-id": "AYXXeGOkF6fauVGt4q9iEAI_TmxYmLhR-9DCi7q_j1K7Q2N1Xa7TtAGsv1D6jtpzC1VBJNrHiJCr4tuY&enable-funding=venmo&currency=USD",
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setPurchaseSuccessful(false)
  }

  return (<>
    <div style={{ maxWidth: '500px' }}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  reference_id: "PUHF",
                  description: "A beautiful golden coin!",
                  custom_id: "dsfwe7364",
                  soft_descriptor: "Beautiful coin",
                  amount: {
                    currency_code: "USD",
                    value: props.value,
                    breakdown: {
                      item_total: {
                        currency_code: "USD",
                        value: props.value
                      }
                    }
                  },
                  items: [
                    {
                      name: "Item 1",
                      description: "The best item ever",
                      sku: "xyz-2654",
                      unit_amount: {
                        currency_code: "USD",
                        value: props.coinPrice
                      },
                      quantity: props.quantity
                    },
                  ],
                }
              ]
            })
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              ApiService.addPremiumToUser({
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Token ' + localStorage.getItem('token') 
                },
              })
              .then((res) => res.json())
              .then((data) => {
                if (data.status === 'Success') {
                  setPurchaseSuccessful(true)
                  props.setIsPremiumUser(true)
                }
              })
              .catch((err) => console.error(err))
            })
          }}
        />
      </PayPalScriptProvider>
    </div>
    <Stack 
      spacing={2} 
      sx={{ width: '100%' }}
    >
      <Snackbar 
        open={purchaseSuccessful} 
        autoHideDuration={4000} 
        onClose={handleClose} 
        sx={{marginLeft: 'calc(50% - 150px)'}}
      >
        {purchaseSuccessful && 
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Successfully purchased {props.quantity} coins!
          </Alert>
        }
      </Snackbar>
    </Stack>
  </>)
}