import React, {useState, useEffect} from "react";
import Section from "@Typed/components/layout/Section";
import Waves from "@Typed/components/dividers/Waves";

export default function BenefitsSection() {
    const [opacity, setOpacity] = useState(0.8);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            setOpacity(0.8 + Math.min(scrollY / windowHeight, 1));
        };
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className="relative px-6 pb-32 bg-fixed bg-center bg-no-repeat bg-cover bg-hero-pattern lg:px-8">
            <div className="absolute inset-0 w-full h-full bg-blue-900" style={{opacity: opacity}}></div>
            <Waves className="top-0 -scale-x-100" style={{opacity: (opacity - 0.8)*3}}/>
            <Section animate direction="up" id="benefits" >
                <div className="flex flex-col items-center max-w-md gap-16 pt-24 mx-auto md:max-w-screen-xl md:flex-row md:items-start">
                    <div className="flex flex-col items-center gap-4 text-center basis-1/3">
                        <img src="/images/undraw_learning.svg" className="hidden w-60 h-60 md:block" />
                        <h2 className="text-xl font-bold tracking-tight text-white font-averia sm:text-2xl">
                            Personalized Family Journey
                        </h2>
                        <p className="text-lg leading-8 text-gray-100">
                        Navigate your unique family path with ease. Our platform offers tailored support, ensuring your family's journey is as unique as you are.
                        </p>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-center basis-1/3">
                        <div className="items-center hidden w-60 h-60 md:flex"><img src="/images/undraw_mind_map.png" className="w-full" /></div>
                        <h2 className="text-xl font-bold tracking-tight text-white font-averia sm:text-2xl">
                        Comprehensive Services
                        </h2>
                        <p className="text-lg leading-8 text-gray-100">
                        Access a wide range of services from research tools to matchmaking for family-related needs. Simplify your search for information and support.
                        </p>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-center basis-1/3">
                        <img src="/images/undraw_notebook.svg" className="hidden w-60 h-60 md:block" />
                        <h2 className="text-xl font-bold tracking-tight text-white font-averia sm:text-2xl">
                        Expert-Backed Solutions
                        </h2>
                        <p className="text-lg leading-8 text-gray-100">
                        Benefit from our blend of technology and expert knowledge. Make informed decisions about your family goals with confidence and accuracy.
                        </p>
                    </div>
                </div> 
            </Section>
            <Waves className="bottom-0 rotate-180"/>
        </div>
    )
}