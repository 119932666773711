import React from "react";
import Section from "@Typed/components/layout/Section";
import ButtonLink from "@Typed/components/buttons/ButtonLink";
import Tooltip from "@Typed/components/tooltips/Tooltip";
import { InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export default function CommunitySection() {
    return (
        <div className="px-6 py-8 shadow-inner lg:px-8 bg-jade-500 shadow-jade-600 ">
            <Section id="community">
                <div className="flex flex-col items-center justify-center w-full max-w-screen-xl gap-8 p-4 mx-auto text-white ">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold tracking-tight font-averia sm:text-5xl ">
                            You are not alone.
                        </h1>
                        <p className="max-w-xl mt-6 text-lg font-bold leading-8">
                            Connect with other families who are going through the same journey as you. Share your experiences, ask questions, and get support from our community in a moderated safe space.
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-4 text-jade-600">
                        <div className="w-full rounded-l-lg h-min flex gap-4">
                            <div className="z-10 flex flex-col items-start gap-4 p-8 bg-white rounded-lg">
                                <h2 className="text-2xl font-bold leading-6 font-averia">
                                    Explore our interactive discussion hub
                                </h2>
                                <p>
                                   <span className="font-bold text-jade-600">Dive into a world of shared wisdom and heartfelt support in our discussion hub.</span> This platform is a treasure trove of insights, where you can embark on an enlightening journey alongside others who understand your path. Here, every voice matters and every question is valued.
                                </p>
                                <p>
                                    <span className="font-bold text-jade-600">Engage in enriching dialogues about the nuances of family planning.</span> From the initial steps of considering a family to navigating the complex emotions and decisions along the way, our forum is your go-to destination for guidance and empathy.
                                </p>
                                <p>
                                    <span className="font-bold text-jade-600">Our dedicated space is meticulously moderated to ensure a respectful and nurturing environment.</span> Whether you're seeking advice, yearning to share your personal story, or hoping to find solace in the experiences of others, our Discussion Hub is your safe harbor in the vast sea of family planning.
                                </p>
                                <p>
                                    <span className="font-bold text-jade-600">In this haven of shared experiences, no topic is too small or too challenging.</span> We believe in the power of collective wisdom and the strength of community support. Be part of a network that uplifts, educates, and empowers.
                                </p>
                                <ButtonLink href="/home" buttonColor="jade" className="self-end">Visit our discussion hub</ButtonLink>
                            </div>
                            <img src="/images/topics_preview.png" className="hidden rounded-lg shadow-xl shadow-black/25 md:block h-full w-auto p-1 bg-white ml-4" />
                        </div>
                        <hr className="w-full border-gray-300" />
                        <div className="w-full text-gray-600 rounded-r-lg h-min flex gap-4">
                            <img src="/images/reddit_preview.png" className="hidden rounded-lg shadow-xl shadow-black/25 md:block ml-auto mr-4 h-full w-auto p-1 bg-white" />
                            <div className="z-10 flex flex-col items-start gap-4 p-8 bg-white rounded-lg">
                                <h2 className="text-2xl font-bold leading-6 font-averia">
                                    Join our <span className="text-[#ff4500]">Reddit</span> community
                                </h2>
                                <div>
                                    <span className="font-bold">🌟  A Warm and Welcoming Space</span> 
                                    <p>Step into a vibrant community where warmth and understanding are the foundations.</p>
                                </div>
                                <div>
                                    <span className="font-bold">💬 Real-Time Conversations and Support</span> 
                                    <p>Engage in live discussions with people who share your experiences and aspirations.</p>
                                </div>
                                <div>
                                    <span className="font-bold">🔒 Safe and Moderated Environment</span> 
                                    <p>We prioritize the safety and comfort of our members. Our dedicated moderators work tirelessly to maintain a respectful and supportive atmosphere.</p>
                                </div>
                                <div>
                                    <span className="font-bold">🌱 Grow and Connect</span> 
                                    <p>Participate in regular community events, Q&A sessions with experts, and fun social gatherings. Our subreddit is more than just a discussion forum; it's a place to build lasting connections and grow together as a community.</p>
                                </div>
                                <div className="flex items-center justify-between w-full mt-4">
                                    <div className="flex flex-col">
                                        <span className="relative inline-flex items-center gap-1 font-bold text-[#ff4500] group cursor-help group-hover:text-blue-600">
                                            What is Reddit?
                                            <InformationCircleIcon className="w-5 h-5" aria-hidden="true" />
                                            <Tooltip>
                                            <p>Reddit is a web platform where users can join and participate in communities, share content, comment on posts, and engage in discussions.</p>
                                            </Tooltip>
                                        </span>
                                        <a href="https://www.redditinc.com/" className="leading-6 text-gray " target="_blank">
                                            To learn more, visit the Reddit website <span aria-hidden="true">→</span>
                                        </a>
                                    </div>
                                    
                                    <ButtonLink buttonColor="jade" className="text-center" href="https://www.reddit.com/r/FamilyDesign/">Visit our subreddit</ButtonLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Section>
        </div>
    )
}