import React from "react";

export interface ButtonLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    buttonColor?: string;
    labelColor?: string;
}

export default function ButtonLink({ buttonColor = "blue", labelColor = "white", className, ...props }: ButtonLinkProps) {


    const hoverClass = `hover:bg-${buttonColor}-600 cursor-pointer`;
    const focusClass = `focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${buttonColor}-600`;
    const className_ = `rounded-full px-3.5 py-2.5 font-semibold shadow-sm ${hoverClass} ${focusClass} bg-${buttonColor}-500 text-${labelColor} ${className}`;

    return <a {...props} className={className_}/>;
}