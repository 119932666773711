import React from "react";
import Section from "@Typed/components/layout/Section";
import ButtonLink from "@Typed/components/buttons/ButtonLink";

export default function ServicesSection() {
    return (
        <div className="px-6 py-8 lg:px-8">
            <Section animate direction="right" id="services">
                <div className="flex flex-col items-center w-full max-w-screen-xl gap-32 p-4 mx-auto md:flex-row ">
                    <img src="/images/medium-shot-smiley-woman-with-laptop.jpg" className="order-last hidden rounded-lg shadow-xl w-80 shadow-black/25 md:order-second sm:block" />
                    <div className="text-left">
                        <h1 className="text-2xl font-bold tracking-tight text-blue-500 font-averia sm:text-4xl ">
                            Tailored Services
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-inherit">
                            We offer personalized services to make your family planning journey easier and faster. Whether it's finding the right doctor, getting the right tests, or getting the right advice, we're here to help you. 
                        </p>
                        <div className="flex flex-col items-start justify-center w-full gap-4 mt-8">
                            <div className="flex flex-col items-start w-full gap-2 px-8 py-2">
                                <a href="#" className="text-lg font-bold leading-6 text-blue-500 font-averia hover:text-blue-600">
                                    Talk to a family advisor <span aria-hidden="true">→</span>
                                </a>
                                <p>Our family advisors can help you navigate you on your journey by offering expert and personal insights, and help you get the most out of the tools and services we offer.</p>
                            </div>
                            <hr className="w-full border-gray-300" />
                            <div className="flex flex-col items-start w-full gap-2 px-8 py-2">
                                <a href="#" className="text-lg font-bold leading-6 text-blue-500 font-averia hover:text-blue-600">
                                    Ask about our clinical matching services <span aria-hidden="true">→</span>
                                </a>
                                <p>Our clinical matching services can help you find the right doctor or clinic for your needs.</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-end mt-10">
                            <a href="#" className="font-semibold leading-6 text-blue-500 hover:text-blue-800">
                                More tools <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div> 
            </Section>
        </div>
    )
}